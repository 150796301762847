import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useMedicineList() {

  const searchQuery = ref('');
  const selected_tag = ref('');
  const selected_status = ref('');
  const refListTable = ref(null);
  const currentPage = ref(1);
  const pageLength = ref(10);
  const Total = ref(0);
  const Tag_Total = ref(0);
  const Type_Total = ref(0);
  const dir = ref(false);
  const toast = useToast()
  const tableColumns = [

      { field: 'id', label: 'ไอดี', sortable: false, hidden:true },
        { field: 'title', label: 'ชื่อ', sortable: false },
        { field: 'is_active', label: 'สถานะ', sortable: false },
        { field: 'action', label: 'การจัดการ', sortable: false },


    ]
    const Data = ref([]);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    const refetchData = () => {
      refListTable.value.refresh()
  }

    watch([currentPage, pageLength, searchQuery, selected_status], () => {
      fetchData();
    })

    const fetchData = async () =>{
      const offset = pageLength.value*(currentPage.value-1);
      Data.value  = [];
      await store
            .dispatch('app-patients-tag/fetchTagData', {
              limit: pageLength.value,
              offset:offset,
              keywords: searchQuery.value,
              branch_id:branch_id,
              group_id:selected_tag.value,
              is_active:selected_status.value,
              order_by:'id DESC'
          
            })
            .then(response => {
              Data.value = response.data.data;
              console.log(Data.value);
              const total = response.data.total?response.data.total: Data.value.length;
              Tag_Total.value = total;
            })
            .catch((error) => {
              console.log('error',error);
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error fetching patients tag list",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
    }

    fetchData();
    const deleteData = (id) => {
      //fetchDeleteProperty
      var data = {id:id}
      var res = store.dispatch('app-patients-tag/deleteTagData',data)

      res.then(response=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Delete Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        fetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Delete",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })
      //console.log('id', id);
      
    }

    const resolveUserStatusVariant = status => {
       
      if (status) return 'success'
      if (!status) return 'secondary'
      return 'primary'
    }
    const resolveUserStatusnameVariant = status => {
    
        if (status) return 'Active'
        if (!status) return 'Inactive'
        return 'primary'
    }

    return {
        tableColumns,
        Total,
        Data,
        pageLength,
        dir,
        searchQuery,
        selected_status,
        currentPage,
        refListTable,
        deleteData,
        fetchData,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant
    }
}